<template>
  <div>
    <v-simple-table fixed-header height="calc(100vh - 145px)" class="table-padding-2">
      <template v-slot:default>
        <thead class="v-data-table-header">
          <tr>
            <th role="columnheader" class="text-center">
              <SelectPos :label="$t('labels.pos')" :placeholder="$t('labels.pos')" name="id_pos" sort-name="pos_code"
                has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th role="columnheader" class="text-center">
              <InputFilter :label="$t('labels.tracking')" :placeholder="$t('labels.tracking')" name="tracking_id"
                sort-name="tracking_id" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <template v-if="['view1'].includes(viewOption)">
              <th role="columnheader" class="text-center">
                <InputFilter :label="$t('labels.delivery_partner_state')"
                  :placeholder="$t('labels.delivery_partner_state')" name="delivery_partner_state"
                  sort-name="delivery_partner_state" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                  @onSort="onSortChange" />
              </th>
              <th role="columnheader" class="text-center">
                <SelectFilter :options="deliveryStateOption" :label="$t('labels.delivery_state')"
                  :placeholder="$t('labels.delivery_state')" name="id_delivery_state" sort-name="delivery_state_name"
                  has-sort :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
              </th>
            </template>
            <th>
              <SelectFilter :options="imageOptions" :label="$t('labels.image')" :placeholder="$t('labels.image')"
                name="image" sort-name="url_images" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th>
              <InputFilter :label="$t('labels.sku')" :placeholder="$t('labels.sku')" name="sku" sort-name="sku" has-sort
                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th>
              <InputFilter :label="$t('labels.barcode')" :placeholder="$t('labels.barcode')" name="customer_goods_barcode"
                sort-name="customer_goods_barcode" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <template v-if="['view1'].includes(viewOption)">
              <th role="columnheader" class="text-center">
                <DateRangeFilter :label="$t('labels.return_receive_time')" :placeholder="$t('labels.return_receive_time')"
                  name="confirm_return_at" sort-name="confirm_return_at" has-sort :sorting="filters.sort_by"
                  @onFilter="onFilterChange" @onSort="onSortChange" />
              </th>
              <th role="columnheader" class="text-center">
                <DateRangeFilter :label="$t('labels.return_import_time')" :placeholder="$t('labels.return_import_time')"
                  name="receipt_return_at" sort-name="receipt_return_at" @onFilter="onFilterChange"
                  @onSort="onSortChange" />
              </th>
              <th role="columnheader" class="text-center">
                <SelectEmployee :label="$t('labels.return_receive_employee')"
                  :placeholder="$t('labels.return_receive_employee')" name="confirm_return_by"
                  sort-name="confirm_return_by" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                  @onSort="onSortChange" />
              </th>
              <th role="columnheader" class="text-center">
                <SelectEmployee :label="$t('labels.return_import_employee')"
                  :placeholder="$t('labels.return_import_employee')" name="receipt_return_by"
                  sort-name="receipt_return_by" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                  @onSort="onSortChange" />
              </th>
            </template>
            <th role="columnheader" class="text-center">
              <SelectFilter :options="sealStatusOptions" :label="$t('labels.seal_status')"
                :placeholder="$t('labels.seal_status')" name="seal_status" sort-name="seal_status" has-sort
                :sorting="filters.sort_by" @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
            <th>
              <InputFilter :label="$t('labels.export')" :placeholder="$t('labels.export')" name="order_quantity"
                sort-name="order_quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th>
              <InputFilter :label="$t('labels.import')" :placeholder="$t('labels.import')" name="quantity"
                sort-name="quantity" has-sort :sorting="filters.sort_by" @onFilter="onFilterChange"
                @onSort="onSortChange" />
            </th>
            <th>
              <InputFilter :label="$t('labels.sub_quantity')" :placeholder="$t('labels.sub_quantity')"
                name="diff_quantity" sort-name="diff_quantity" has-sort :sorting="filters.sort_by"
                @onFilter="onFilterChange" @onSort="onSortChange" />
            </th>
          </tr>
        </thead>
        <tbody>
          <tr v-for="item in items" :key="item.id" class="text-center">
            <td>{{ item.pos_code }}</td>
            <td>
              <OrderTracking :tracking-id="item.tracking_id" />
            </td>
            <template v-if="['view1'].includes(viewOption)">
              <td>{{ item.delivery_partner_state }}</td>
              <td>{{ item.delivery_state_name }}</td>
            </template>
            <td>
              <ImageViewer v-if="item.url_images" :url="item.url_images" />
            </td>
            <td>{{ item.sku }}</td>
            <td>{{ item.customer_goods_barcode }}</td>
            <template v-if="['view1'].includes(viewOption)">
              <td>{{ item.confirm_return_at ? formatDateTime(item.confirm_return_at) : '' }}</td>
              <td>{{ item.receipt_return_at ? formatDateTime(item.receipt_return_at) : '' }}</td>
              <td>{{ item.employee_return_receive_name }}</td>
              <td>{{ item.employee_return_import_name }}</td>
            </template>
            <td>{{ item.seal_status === 1 ? $t('labels.seal_status_1') : $t('labels.seal_status_0') }}</td>
            <td>{{ item.order_quantity }}</td>
            <td>
              {{ item.quantity }}
              <!-- <span v-if="checkPermission(['goods_return_update'])"
                  class="cursor-pointer text-decoration-underline primary--text"
                  @click="showUpdateDialog(item)" :title="item.note">
              {{ item.quantity }}
            </span>
            <span v-else :title="item.note">{{ item.quantity }}</span> -->
            </td>
            <td><span :title="item.note">{{ item.order_quantity - item.quantity }}</span></td>
          </tr>
        </tbody>
      </template>
    </v-simple-table>

    <v-row class="pt-3">
      <v-col cols="12" sm="3">
        <div class="d-flex table-footer-count">
          <div class="mr-2">{{ $t('labels.tracking') }}: <b>{{ formatNumber(sum.sumOrder) }}</b></div>
          <div class="mr-2">{{ $t('labels.sku') }}: <b>{{ formatNumber(sum.sumGoods) }}</b></div>
        </div>
        <div class="d-flex table-footer-count mt-1">
          <div class="mr-2">{{ $t('labels.export') }}: <b>{{ formatNumber(sum.sumOrderQuantity) }}</b></div>
          <div class="mr-2">{{ $t('labels.import') }}: <b>{{ formatNumber(sum.sumQuantity) }}</b></div>
          <div class="mr-2">{{ $t('labels.sub_quantity') }}: <b>{{ formatNumber(sum.sumOrderQuantity - sum.sumQuantity)
          }}</b></div>
        </div>
      </v-col>
      <v-col cols="12" sm="6" class="text-right">
        <v-pagination v-model="page" :length="totalPage" :total-visible="6"></v-pagination>
      </v-col>
      <v-col cols="12" sm="3">
        <div class="d-flex">
          <v-btn color="success" @click="exportExcel" v-if="checkPermission(['goods_return_excel'])"
            style="height: 40px" class="mr-2"><v-icon>mdi-download</v-icon> {{ $t('labels.excel') }}</v-btn>
          <v-text-field class="c-input-xs mr-2" dense outlined hide-details v-model.number="page"
            :label="$t('labels.page')" :placeholder="$t('labels.page')"></v-text-field>
          <v-btn color="secondary" style="height: 40px" @click="setViewOption('view0')"
            v-if="['view1'].includes(viewOption)"><v-icon>mdi-arrow-collapse</v-icon> {{ $t('views.compact') }}</v-btn>
          <v-btn color="primary" style="height: 40px" @click="setViewOption('view1')"
            v-if="['view0'].includes(viewOption)"><v-icon>mdi-arrow-expand</v-icon> {{ $t('views.full') }}</v-btn>
        </div>
      </v-col>
    </v-row>

  </div>
</template>


<script>
import { GOODS_ISSUE_STATE, DELIVERY_STATE, IMAGE_OPTIONS, GOODS_RETURN_SEAL_STATUS } from "@/libs/const";
import { httpClient } from "@/libs/http";
import { debounce } from "lodash/function";

export default {
  components: {
    SelectPos: () => import('@/components/table/SelectPos'),
    SelectFilter: () => import('@/components/table/SelectFilter'),
    InputFilter: () => import('@/components/table/InputFilter'),
    DateRangeFilter: () => import('@/components/table/DateRangeFilter'),
    SelectEmployee: () => import('@/components/table/SelectEmployee'),
    ImageViewer: () => import('@/components/common/ImageViewer'),
    OrderTracking: () => import('@/components/common/OrderTracking'),
  },
  name: "Index",
  data: () => ({
    page: 1,
    totalPage: 1,
    items: [],
    viewOption: 'view0',
    filters: {},
    imageOptions: [...IMAGE_OPTIONS],
    sealStatusOptions: [...GOODS_RETURN_SEAL_STATUS],
    stateOptions: [...GOODS_ISSUE_STATE],
    deliveryStateOption: [...DELIVERY_STATE],
    updatingItem: {},
    sum: {},
    quantityDialog: false,
    isLoading: false
  }),
  watch: {
    page() {
      this.getList()
    },
    filters: {
      handler() {
        this.page = 1
        this.getList()
      },
      deep: true
    }
  },
  methods: {
    setViewOption(mode) {
      this.viewOption = mode
    },
    getList: debounce(function () {
      httpClient.post('/order-online-return-list', { ...this.filters, page: this.page }).then(({ data }) => {
        this.totalPage = data.totalPage
        this.items = [...data.rows]
        this.sum = { ...data.sum }
      })
    }, 1000),
    onFilterChange(filter) {
      this.filters = { ...this.filters, [filter.name]: filter.value }
    },
    onSortChange(sort) {
      this.filters = { ...this.filters, ...sort }
    },
    async exportExcel() {
      if (this.isLoading) {
        this.$vToastify.warning(this.$t('messages.waiting_file_download'))
        return false
      }
      this.isLoading = true
      const filename = 'hoan.xlsx'

      try {
        await this.downloadExcelFile('/order-online-return-list-export', { ...this.filters }, filename)
        this.isLoading = false
      } catch (e) {
        this.isLoading = false
      }
    },
    urlTrackingDelivery(item) {
      let tracking_link = item.delivery_tracking_link || ''
      if (tracking_link && tracking_link.includes('[delivery_order_id]')) {
        return tracking_link.replace('[delivery_order_id]', item.delivery_order_id)
      } else {
        return `${tracking_link + item.delivery_order_id}`
      }
    },
    showUpdateDialog(item) {
      this.quantityDialog = true
      this.updatingItem = { ...item }
    },
    hideUpdateDialog() {
      this.quantityDialog = false
      this.updatingItem = {}
    }
  },
  mounted() {
    this.getList()
  }
}
</script>

<style scoped></style>
